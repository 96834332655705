export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglès"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castellà"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turc"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "zgh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ⵜⴰⵎⴰⵣⵉⵖⵜ"])},
  "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Català"])},
  "ary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dàrija"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francès"])},
  "ber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaziɣt"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase aleatòria"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lar"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu alguna cosa per traduir..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gràcies per la vostra contribució!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicació prototip construïda per Col·lectivaT. Models de traducció automàtica basats en el model NLLB de META AI i no s'han de confiar com a traduccions adequades."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductor Amazic"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductor Amazic"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amb el suport de"])},
  "supportgene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amb el suport de l'Agència Catalana de Cooperació al Desenvolupament i del Departament de Drets Socials"])},
  "iniciative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una iniciativa de"])}
}