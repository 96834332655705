export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
  "lad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladino"])},
  "zgh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ⵜⴰⵎⴰⵣⵉⵖⵜ"])},
  "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])},
  "ary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darija"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "ber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaziɣt"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Sentence"])},
  "Contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "Type something to translate...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type something to translate..."])},
  "Thank you for your contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your contribution!"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a prototype application built by Col·lectivaT. Machine translation models based on META AI's NLLB model and should not be trusted as proper translations."])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamazight Translate"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamazight Translate"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With support from"])},
  "supportgene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the support of the Catalan Development Cooperation Agency and the Department of Social Rights"])},
  "iniciative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An iniciative by"])}
}